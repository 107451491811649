import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {useTranslation} from 'gatsby-plugin-react-i18next';

const AboutHero = () => {
  const {t} = useTranslation();
  return(
    <section className="text-blue-900 bg-blue-50 body-font pt-5">
      <div className="my-cont flex md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="text-3xl mb-5 font-normal mt-0">{t('hero.title')}</h1>
          <h3 className="mb-8 text-lg">{t('hero.p1')}</h3>
          <h3 className="mb-8 text-lg">{t('hero.p2')}</h3>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <StaticImage className="rounded-lg" src="../images/danang.jpg" alt="Danang beach" />
          {/* <img className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" /> */}
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
