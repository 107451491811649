import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import FormInquiry from './FormInquiry';

const AboutContact = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font relative pt-10">
      <div className="my-cont flex lg:flex-row flex-col items-start">
        <div className="w-full h-96 lg:w-1/2 lg:h-screen bg-gray-100 p-10 lg:mr-10 flex relative">
          <iframe width="100%" height="100%" className="absolute inset-0 rounded-lg" frameBorder="0" title="map" marginHeight="0" marginWidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3833.825275203584!2d108.2217728!3d16.0745542!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314218306fd3bd13%3A0xfb7f8a981c731e94!2s!5e0!3m2!1sen!2shk!4v1621444870890!5m2!1sen!2shk"></iframe>
        </div>
        <div className="lg:w-1/2 flex flex-col lg:ml-auto lg:py-8 mt-8 lg:mt-0">
          <h2 className="text-blue-900 text-2xl mb-1 font-medium title-font">{t('formabout.title')}</h2>
          <p className="leading-relaxed mb-5 text-gray-600">{t('formabout.tag')}</p>
          {/* <div className="relative mb-4">
            <label for="name" className="leading-7 text-sm text-gray-600">{t('form.name')}</label>
            <input type="text" id="name" name="name" className="w-full bg-white rounded border border-gray-300 focus:border-blue-900 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>
          <div className="relative mb-4">
            <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-blue-900 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>
          <div className="relative mb-4">
            <label for="message" className="leading-7 text-sm text-gray-600">{t('form.message')}</label>
            <textarea id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-blue-900 focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
          <button className="text-white bg-yellow-400 border-0 py-2 px-6 focus:outline-none hover:bg-blue-800 rounded text-lg">Send</button> */}

          <div id="inquiryform"></div>
          <FormInquiry />

          <p className="text-xs text-gray-500">{t('formabout.sub')}</p>
        </div>
      </div>
    </section>
  );
};

export default AboutContact;
