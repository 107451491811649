import * as React from "react";
import {graphql} from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import AboutHero from '../components/AboutHero';
import AboutHistory from "../components/AboutHistory";
import AboutTeam from "../components/AboutTeam";
import AboutContact from '../components/AboutContact';

// markup
const AboutPage = () => {
  return (
    <Layout>
      <Seo />
      <AboutHero />
      <AboutHistory />
      <AboutTeam />
      <AboutContact />
    </Layout>
  )
};

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "about"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
