import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import imgFounders from '../images/founders.jpg';

const AboutTeam = ({ img, title, desc }) => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font pt-5 px-5">
      <div className="container mx-auto flex px-5 py-10 items-center justify-center flex-col">
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-900">{t('team.title')}</h1>
        </div>
        <img src={imgFounders} className="w-5/6 lg:w-1/2 mb-5 object-cover object-center rounded" alt="" />
        <div className="flex justify-between text-center w-5/6 lg:w-1/2">
          <div className='md:pl-28'>
            <h1 className="title-font text-xl mb-2 font-medium text-blue-900">{t('team.founder')}</h1>
            <p>{t('team.role')}</p>
          </div>
          <div className='md:pr-28'>
            <h1 className="title-font text-xl mb-2 font-medium text-blue-900">{t('team.cofounder')}</h1>
            <p>{t('team.corole')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTeam