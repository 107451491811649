import React from 'react';
import Divider from './Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const AboutHistory = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font bg-steps pt-8 mb-8">
      <div className="my-cont">
        <Divider title={t('history.title')} />
        <div className="text-center mb-10">
          <p className="leading-relaxed lg:w-3/5 mx-auto text-3xl text-blue-900">{t('history.desc')}</p>
        </div>
        <div className="flex flex-wrap">
          <div className="flex relative pt-5 pb-20 sm:items-center md:w-2/3 mx-auto w-full">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-yellow-300 pointer-events-none rounded-t"></div>
            </div>
            <div className="flex-shrink-0 w-20 h-6 rounded-full inline-flex items-center justify-center bg-blue-800 text-white relative z-10 title-font font-medium text-sm">2020</div>
            <div className="flex-grow md:pl-8 pl-6 flex items-center flex-col sm:flex-row">
              <div className="sm:pl-6 flex items-center">
                <h2 className="text-blue-900 text-xl font-medium">{t('history.2020')}</h2>
              </div>
            </div>
          </div>
          <div className="flex relative pt-5 pb-20 sm:items-center md:w-2/3 mx-auto w-full">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-yellow-300 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 w-20 h-6 rounded-full inline-flex items-center justify-center bg-blue-800 text-white relative z-10 title-font font-medium text-sm">2021</div>
            <div className="flex-grow md:pl-8 pl-6 flex items-center flex-col sm:flex-row">
              <div className="sm:pl-6 flex items-center">
                <h2 className="text-blue-900 text-xl font-medium">{t('history.2021')}</h2>
              </div>
            </div>
          </div>
          <div className="flex relative pt-5 pb-20 sm:items-center md:w-2/3 mx-auto w-full">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-yellow-300 pointer-events-none rounded-b"></div>
            </div>
            <div className="flex-shrink-0 w-20 h-6 rounded-full inline-flex items-center justify-center bg-blue-800 text-white relative z-10 title-font font-medium text-sm">2022</div>
            <div className="flex-grow md:pl-8 pl-6 flex items-center flex-col sm:flex-row">
              <div className="sm:pl-6 flex items-center">
                <h2 className="text-blue-900 text-xl font-medium">{t('history.2022')}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHistory;
